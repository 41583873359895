@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "./trello-theme.less"; // Import Ant Design styles by less entry

// @primary-color: #367fee;
@blue-base: rgb(0, 120, 212);
@btn-font-weight: 600;
// @modal-mask-bg: rgba(0, 0, 0, 0.2); AZURE
// @modal-mask-bg: rgba(0, 0, 0, 0.64); TRELLO
@modal-mask-bg: rgba(38, 41, 44, 0.32); // Pipedrive
@animation-duration-base: 0;

// Layout
.ant-layout {
    background-color: var(--shade--100);
}

// Button
.ant-btn {
    // transition: var(--transition);
    transition: none;
    border-radius: 6px;
    max-height: 38px;
    height: 38px;
    padding: 6px 16px;
    font-size: 16px;
    font-weight: 500;
    background-color: #eaebec;
    border-color: #eaebec;
}

.ant-btn:hover,
.ant-btn:focus {
    background-color: #e0e2e3;
    border-color: #e0e2e3;
    color: black;
    // color: #0d544e;
}

.ant-btn-text {
    background-color: transparent;
    border-color: transparent;
    color: var(--shade-10);
}
.ant-btn-text svg {
    fill: var(--shade-20);
}
.ant-btn-text:hover,
.ant-btn-text:focus {
    background-color: #eaebec;
    border-color: #eaebec;
    color: var(--shade-05);
}
.ant-btn-text:active {
    background-color: rgba(0, 0, 0, 0.05) !important;
}
.ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        font-size: 19px;
    }
}

// .ant-btn:not(.ant-btn-primary) {
//     border-color: transparent;
//     background-color: rgba(0, 0, 0, 0.06);
//     color: rgba(0, 0, 0, 0.9);
// }
// .ant-btn:not(.ant-btn-primary):hover {
//     border-color: transparent;
//     background-color: rgba(0, 0, 0, 0.1);
// }

.ant-btn.normal {
    // transition: none;
    background-color: #eaebec;
    border-color: #eaebec;
    // box-shadow: none;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background-image: linear-gradient(to bottom, #fff, #f3f5f7);
    // border: 1px solid #cfd7df;
    // color: var(--shade-05);
    // border-radius: 3px;
    // height: 32px;
    // min-width: 32px;
}
.ant-btn.normal:hover {
    background-color: #c9eeea;
    border-color: #c9eeea;
    color: #0d544e;
}
// .ant-btn.normal:active {
//     box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.25);
// }
// .ant-btn.normal:focus {
//     color: rgba(0, 0, 0, 0.65);
// }
.ant-btn.normal[disabled] {
    opacity: 0.5;
    pointer-events: none;
}

.ant-btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    // border-radius: 2px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: #fff;
}
.ant-btn-primary.dark {
    background-color: rgba(0, 0, 0, 0.85);
    border-color: rgba(0, 0, 0, 0.85);
}
.ant-btn-primary.dark:hover,
.ant-btn-primary.dark:focus {
    background-color: rgba(0, 0, 0, 0.65);
    border-color: rgba(0, 0, 0, 0.65);
    color: #fff;
}
// Botao roxo
.ant-btn-primary.purple {
    background-color: #686de0;
    border-color: #686de0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border-radius: 2px;
}
.ant-btn-primary.purple:hover,
.ant-btn-primary.purple:focus {
    background-color: #4834d4;
    border-color: #4834d4;
}
// Botao azul
.ant-btn-primary.blue {
    background-color: #0050dc;
    border-color: #0050dc;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border-radius: 2px;
}
.ant-btn-primary.blue:hover,
.ant-btn-primary.blue:focus {
    background-color: #006fff;
    border-color: #006fff;
}

.ant-btn-dangerous.ant-btn-primary {
    background-color: #e74c3c !important;
    border-color: #e74c3c;

    &:hover {
        background-color: #c0392b !important;
        border-color: #c0392b;
    }
}

.ant-card-head {
    border-bottom: 1px solid #fff;
    // background-color: #2980b9;
    // color: white;
    border-radius: 16px 16px 0 0;
}

// Drawer
.ant-drawer-header {
    border-bottom: none;
    // background-color: @trelloBg;
}
.ant-drawer-title {
    font-size: 16px;
}
// .ant-drawer-body {
//     padding-top: 10px;
// }

// Switch
.ant-switch {
    background-color: rgba(233, 233, 233);
}

.ant-switch-handle:before {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
}

.ant-switch-checked {
    background-color: #237dbd;
}

// Drawer
.ant-drawer > * {
    transition: transform 0.15s;
}

// Input
.ant-form-item-explain {
    margin: 5px 0;
}

// Table
.ant-table {
    // background: var(--shade-100);
    // border: 2px solid var(--shade-45);
    border-radius: 6px;
    // box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color,rgba(0, 0, 0, .132)),0 .6px 1.8px 0 var(--callout-shadow-secondary-color,rgba(0, 0, 0, .108));
    // border: 1px solid #eaeaea;
    // border: 1px solid #dadce0;
    border: none;
    overflow: auto;
}

.ant-table-tbody > tr:hover > td,
.ant-table-tbody > tr:focus-within > td {
    background-color: var(--shade-50);
}
.ant-table-tbody > tr > td {
    border-bottom: 1px solid var(--shade-45);
    height: 54px;
}

// Dropdown
.ant-dropdown-menu,
.ant-select-dropdown {
    // border: 1px solid rgba(0, 0, 0, 0.1);
    // border-radius: 6px;
    // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    border: 0;
    padding: 8px 0;

    border-radius: var(--radius-large);
    box-shadow: var(--elevation);
    background-color: var(--shade-100);
    // animation: fade-in 0.25s ease 0s;
}

// .ant-dropdown-menu {
//     animation: fade-in 0.25s ease 2s;
// }

// .ant-dropdown-menu-item > a:hover,
// .ant-dropdown-menu-submenu-title > a:hover {
//     background-color: rgba(93, 105, 133, 0.1);
// }

.ant-dropdown-menu-item {
    font-weight: 500;
    font-size: 14px;
    font-family: var(--font-family-system);
    transition: none;
    display: flex;
    align-items: center;
    line-height: 31px;
    color: var(--shade-05);
    position: relative;

    i {
        font-size: 16px;
        width: 20px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        color: var(--primary);
    }

    &.ant-dropdown-menu-item-disabled {
        opacity: 0.3;
        pointer-events: none;
        &:hover {
            cursor: default;
            background-color: transparent;
            color: var(--shade-05);
        }
    }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    // background-color: rgba(93, 105, 133, 0.1);
    background-color: var(--shade-50);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(93, 105, 133, 0.1);
}

// .ant-dropdown-menu-item > div,
// .ant-dropdown-menu-submenu-title {
//     padding: 5px 80px 5px 20px;
// }

.ant-dropdown-menu-item > i {
    margin-left: 8px;
}
.ant-dropdown-menu-item > span {
    margin-right: 58px;
}
// .ant-dropdown-menu-item a {
//     margin-right: 38px;
// }

// Collapse
.ant-collapse-content {
    color: var(--shade-05);
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 16px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    transition: none;
}

// Typography
.ant-typography {
    color: var(--shade-05);
}

h4.ant-typography,
.ant-typography h4 {
    color: var(--shade-05);
}

// Form
.ant-form-small {
    .ant-form-item {
        margin-bottom: 12px;
    }
    .ant-select-multiple .ant-select-selector {
        padding: 3px 4px;
    }
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
    }
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
        line-height: 26px;
    }
    .ant-input-sm {
        padding: 2px 7px;
    }
    .ant-picker-small {
        padding: 2px 7px 2px;
    }
    .ant-select-multiple.ant-select-sm .ant-select-selection-item {
        height: 20px;
        line-height: 18px;
    }
    .ant-input-number-sm input {
        height: 27px;
    }
    &.ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        padding: 0 0 3px;
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
}

.ant-page-header-back-button {
    display: flex !important;
    justify-content: center;
    align-items: center;
    transition: var(--transition);
    width: 32px;
    height: 32px;
    color: var(--shade-20);
    &:hover {
        // background-color: #f7f7f7 !important;
        // border-radius: 4px;
        background: rgba(0, 0, 0, 0.05) !important;
        border-radius: 50%;
        color: #237dbd;
    }
}

.ant-page-header-back {
    margin-right: 5px;
}

.ant-select-multiple .ant-select-selection-item {
    background: var(--shade-45);
    border: 1px solid rgba(0, 0, 0, 0.15);
}

// Breadcrumb

.ant-breadcrumb > span:last-child,
.ant-breadcrumb a,
.ant-breadcrumb > span:last-child a {
    color: var(--shade-05);
    font-weight: 500;
    font-size: 16px;
}

.ant-breadcrumb a:hover {
    color: var(--shade-10) !important;
}

.ant-breadcrumb-separator {
    color: var(--shade-30);
    font-size: 13px;
    font-family: var(--font-family-system);
    font-weight: 500;
}

.ant-btn[disabled] {
    cursor: default;
}

// Header
.ant-layout-header {
    color: var(--shade-20);
    padding: 0;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ant-page-header-heading-title {
    font-weight: 700;
    color: var(--shade-05);
}

.ant-page-header-heading-extra {
    display: flex;
    align-items: center;
}

// Typography
h3.ant-typography,
.ant-typography h3 {
    color: var(--shade-05);
}

.ant-tooltip-inner {
    border-radius: var(--radius-normal);
    font-weight: 400;
    font-size: 13px;
    background-color: var(--shade-00);
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
    background-color: var(--shade--100);
}

.ant-menu-item,
.ant-menu-submenu-title {
    transition: none;
}

.ant-tag {
    transition: none;
    border-radius: 20px;
    &:hover {
        opacity: 1;
    }
}

.menu-item-delete:hover {
    color: #c0392b;
    i {
        color: #c0392b;
    }
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: var(--shade-05);
}

.ant-menu {
    color: var(--shade-05);
}

.ant-spin.ant-spin-spinning {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
        color: var(--primary) !important;
    }
}

.ant-spin-nested-loading {
    flex: 1;
}

// .ant-spin-blur {
//     opacity: 0;
// }

.absolute-spin {
    width: 100%;
    position: absolute;
    padding: 100px;
    top: -1px;
    height: 100%;
    z-index: 1;
    background-color: var(--shade-100);
    opacity: 0.9;
}

.ant-btn-link {
    color: var(--shade-20);
    &:hover,
    &:focus {
        color: var(--shade-00);
    }
}

// FORMS
// =============================
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:hover {
    border-right-width: 1px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector,
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker {
    border: 1px solid var(--shade-30);
    border-radius: 5px;
    transition: none;
    min-height: 38px;
    font-size: 16px;
    // line-height: 38px;
}

.ant-input-number-input {
    min-height: 38px;
}

.ant-input-search-icon::before {
    border-left: 1px solid var(--shade-30);
}

.ant-form-item-label > label {
    font-weight: 500;
}

// .ant-btn:not([disabled]):active {
//     box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.25);
// }

.ant-btn.round {
    border-radius: 20px;
}

.ant-input-affix-wrapper {
    padding-top: 0;
    padding-bottom: 0;
}

.ant-picker-panel-container {
    border-radius: 4px;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    // background: #0078d4;
    // border-color: #0078d4;
    // color: white;
    background-color: rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(to bottom, #fff, #f3f5f7);
    border: 1px solid #cfd7df;
    color: var(--shade-05);

    &:hover,
    &:active {
        background-color: #f3f5f7;
        background-image: none;
        color: var(--shade-05);
    }
    &:active {
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.25);
    }
}

.ant-picker:hover,
.ant-picker-focused {
    border-right-width: 1px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(to bottom, #fff, #f3f5f7);
    border: 1px solid #cfd7df;
    color: var(--shade-05);

    &:hover,
    &:active {
        background-color: #f3f5f7;
        background-image: none;
        color: var(--shade-05);
        border: 1px solid #cfd7df;
    }
    &:active,
    &.ant-pagination-item-active {
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.25);
        border: 1px solid #cfd7df;
    }
    &.ant-pagination-item-active {
        border-color: #0078d4;
    }
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
    color: var(--shade-05);
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
    opacity: 0.25;
    pointer-events: none;
    cursor: default;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
    cursor: default;
}

.ant-empty-description {
    display: none;
}

.ant-table-cell-fix-right {
    // background-color: var(--shade-100);
    // background-color: rgba(253, 253, 253, 0.9) !important;
}

// .message-success .ant-message-notice-content {
//     background-color: #237DBD;
//     color: white;
// }

// .ant-message-notice-content {
//     font-size: 14px;
//     padding: 4px 16px;
//     i {
//         color: #237dbd;
//         margin-right: 12px;
//     }
// }

.ant-btn-primary > a,
.ant-btn-primary:hover > a {
    color: currentColor;
}
.ant-page-header-content {
    padding-top: 8;
}

.ant-modal-confirm-btns {
    display: flex;
}

.ant-btn-dangerous.ant-btn-primary {
    background-color: #f94839;
    &:hover {
        background-color: #fa6559;
    }
}

tr.ant-table-placeholder:hover > td,
.ant-table-placeholder > tr:focus-within > td {
    background-color: white !important;
}

.ant-alert {
    border-radius: 4px;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.85);
}

.active-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-child {
    background-color: #c0392b !important;
    border-color: #c0392b !important;
    &:before {
        background-color: #a33024 !important;
    }
}
.ant-radio-button-wrapper:hover {
    color: var(--primary);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: var(--primary);
    border-color: var(--primary);
    &:hover {
        background-color: var(--primary-hover);
        border-color: var(--primary-hover);
    }
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right: var(--primary-hover);
}

.ant-radio-button-wrapper {
    height: 38px;
    line-height: 38px;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 5px 0 0 5px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 5px 5px 0;
}

.ant-card {
    // border: 1px solid #dadce0;
    // box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color, rgba(0, 0, 0, 0.132)),
    //     0 0.6px 1.8px 0 var(--callout-shadow-secondary-color, rgba(0, 0, 0, 0.108));
    border-radius: 16px;
    // box-shadow: 0 4px 8px rgb(102 102 102 / 15%);
    box-shadow: rgba(102, 102, 102, 0.15) 0px 4px 8px;
}

.ant-select-multiple .ant-select-selection-item {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: black;
    font-weight: 500;
    font-size: 13px;
}

.ant-select-multiple .ant-select-selection-item-remove {
    color: rgba(255, 255, 255, 0.7);
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px;
    min-height: 24px;
}

tr.ant-table-expanded-row > td {
    background-color: transparent;
}

.ant-table-tbody > tr:hover > td {
    background-color: transparent;
}

:not(.ant-form-item-has-error) .ant-form-item-explain div[role="alert"] {
    font-size: 12px;
    // padding: 4px 6px 4px 6px;
    color: rgba(0, 0, 0, 0.65);
    // border-radius: 0px 0px 5px 5px;
    // color: rgba(0,0,0,0.65);
    // background-color: rgba(0,0,0,0.05);
    // padding: 4px 6px 4px 6px;
    // margin-top: -9px;
}
.ant-form-item-has-error .ant-form-item-explain div[role="alert"] {
    border-radius: 0px 0px 5px 5px;
    font-size: 14px;
    color: #cc0007;
    // background-color: #ffcbd6;
    padding: 4px 6px 4px 6px;
    margin-top: -5px;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: #cc0007 !important;
}

.ant-form {
    color: #000;
}

.ant-btn-lg {
    font-size: 15px;
}

.ant-form-item-label > label.ant-form-item-required::before {
    color: #3498db;
}

.ant-card-head-title {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 0;
}

.ant-input[disabled],
.ant-input-number-input[disabled] {
    color: rgba(0, 0, 0, 0.95);
}

.ant-drawer-footer {
    padding: 10px 25px;
}

.nested-table {
    .ant-table {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .ant-table-thead {
        background-color: var(--shade-50);
        .ant-table-cell {
            color: #203b6b;
        }
    }
    .ant-table-pagination.ant-pagination {
        margin: 20px;
    }

    .ant-table-small .ant-table-thead > tr > th {
        background-color: var(--shade-50);
    }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0;
}

.ant-message-notice-content {
    padding: 0;
    background: transparent;
    border-radius: 8px;
}

.ant-message-custom-content {
    padding: 8px;
    padding-right: 15px;
    min-width: 240px;
    border-radius: 8px;
    background-color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
}
.ant-message-custom-content i,
.ant-message-custom-content .anticon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.ant-message-custom-content .anticon {
    color: var(--secondary);
}

.ant-message-success {
    border: 1px solid #18944c;
    color: #18944c;
    background-color: #edfff5;
}
.ant-message-error {
    border: 1px solid #e74c3c;
    color: #e74c3c;
    background-color: #fcedeb;
}
.ant-message-info {
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: #ebf9fc;
}
.ant-message-loading {
    border: 1px solid var(--gray-40);
    color: var(--secondary);
}

.toast-loading {
    animation: loadingCircle 1s infinite linear;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    border-color: transparent;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    // background-color: rgba(0,0,0,0.05);
    background: rgba(255, 255, 255, 0.1);
}

.ant-table-thead th.ant-table-column-sort {
    background: rgba(255, 255, 255, 0.1);
}

.ant-table-row-expand-icon {
    // background: rgba(0,0,0,0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(to bottom, #fff, #f3f5f7);
    border: 1px solid #cfd7df;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
    padding: 5px 8px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: rgba(0, 0, 0, 0.8);
}

.ant-input-search .ant-input::placeholder {
    color: #6c777a;
}

.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button {
    margin-right: 10px;
}

tr.ant-table-row:nth-child(even) {
    background: #f3f5f8;
}

.ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 15px;
    border-bottom: 0;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #eceff3;
}

.ant-alert-warning {
    background-color: #ffe58f;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary);
    border-color: var(--primary);
}

.ant-tabs-tab {
    padding: 8px 24px;
    font-weight: 500;
    color: #78909c;
    border-radius: 10px;
    margin: 0 0 10px;
    font-size: 16px;
}
.ant-tabs-tab:hover {
    color: #000;
    background-color: #eceff3;
}

.ant-tabs-tab.ant-tabs-tab-active {
    color: var(--primary);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
}

.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 30px;
}

.ant-tabs-ink-bar {
    background: var(--primary);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary);
}

.ant-form-item-label > label.ant-form-item-required::before {
    color: var(--primary);
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary);
}

.ant-radio-inner::after {
    background-color: var(--primary);
}

.ant-pagination-options {
    display: none;
}

.ant-card-body::-webkit-scrollbar {
    background-color: #f2f2f2;
    width: 8px;
}
.ant-card-body::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    width: 8px;
    /* visibility: hidden; */
}

.ant-avatar {
    background-color: #e0e2e3;
}

.ant-select-item {
    font-size: 16px;
    line-height: 30px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: var(--primary);
}

td.ant-table-column-sort {
    background: unset;
}

.ant-btn.outlined {
    background-color: transparent;
    color: white;
    border-color: white;
}

.ant-btn.outlined.large {
    padding: 9px 16px;
    height: auto;
    max-height: 42px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    background: white;
    font-weight: 700;
    font-size: 15px;
}

.ant-descriptions-item-label {
    font-weight: 700;
    font-size: 17px;
}
