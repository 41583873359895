@import "~react-quill/dist/quill.snow.css";
@import "~quill-emoji/dist/quill-emoji.css";
@import "~quill-mention/dist/quill.mention.css";

.quill {
    overflow: unset !important;
    padding-right: 0 !important;
    border: 0 !important;
    background-color: transparent !important;
    display: flex;
    flex-direction: column-reverse;
}

.quill .ql-toolbar.ql-snow,
.quill .ql-container.ql-snow {
    border: 0;
    border-top: 1px solid rgb(232, 234, 237);
}

.quill .ql-editor {
    max-width: 100% !important;
}

.quill .ql-editor {
    min-height: 50px;
    padding: 15px 16px 50px !important;
    font-size: 14px;
    font-family: var(--font-family-system);
}

.custom.ql-toolbar.ql-snow {
    border: 0;
    padding-left: 16px !important;

    button {
        margin-right: 5px;
    }
}

.quill .ql-toolbar {
    display: none;
}

.quill.showFormating .ql-toolbar {
    display: block;
    border-bottom: 1px solid rgb(232, 234, 237);
}

.mention {
    background: transparent;
    // color: #487eb0;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,0.65);
    // font-family: monospace;
    // font-size: 13px;
}

.quill.showFormating .ql-editor {
    min-height: 100px;
}