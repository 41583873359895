ul,
menu,
dir {
    display: block;
    list-style-type: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 00px;
    padding: 0;
}

a {
    text-decoration: none;
    transition: fill 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,
        border-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,
        box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
    // color: #3f8abf;
    color: var(--shade-05);
    cursor: pointer;
    display: inline-block;

    &:hover {
        // color: $primary-hover;
        color: var(--shade-05);
        svg {
            fill: $primary-hover;
        }
    }
    &.color-link {
        color: $primary-button;
        font-weight: 500;
        transition: transform 0.1s cubic-bezier(0, 0, 0, 1);
        &:active {
            // box-shadow: 0 0 0 3px rgba(40, 110, 250, 0.2);
            box-shadow: none;
            transform: scale(0.95);
        }
        &:hover {
            color: $primary-button-hover;
        }
    }
}

// .btn {
//     text-transform: uppercase;
//     padding: 6px 12px;
//     border-radius: 3px;
//     font-weight: 900;
//     font-size: 13px;
// }

// button {
//     font-family: "Whitney", "Segoe UI";
// }

h2 {
    margin: 0;
}

h2.box-title {
    font-size: 22px;
    margin-bottom: 20px;
}

p {
    line-height: 1.42857143;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.midcol {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
}

.midcol-sm {
    width: 480px;
    margin: 0 auto;
}

.pad30 {
    padding: 30px;
}

.horizontal-center {
    display: flex;
    justify-items: center;
}

.flex {
    display: flex;
}

.oldschool {
    color: #4f8ed6;
    &:hover {
        color: #4f8ed6;
        text-decoration: underline;
    }
}

li {
    list-style: none;
}

.truncate,
.truncate a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

audio {
    outline: none;
}

figure {
    margin: 0;
}

hr {
    border-bottom: 1px solid #e9ebed;
    border-top: 0;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
}

.text-editor-border-wrapper {
    border: 2px solid var(--shade-45);
    border-radius: 4px;
    &:hover, &:focus-within {
        border-color: #2495e0;
    }
    &:focus-within {
        box-shadow: 0 0 0 2px rgba(0, 120, 212, 0.2);
    }
}
