.ellipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

h4 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 25px;
}

h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #222;
    // font-family: "Inter", "Segoe UI", sans-serif;
}

p {
    margin: 0;
}

.btn-dark {
    --mdc-theme-primary: #363636;;
}

.btn-light {
    --mdc-theme-primary: #dfddd8;
    --mdc-theme-on-primary: #2a2a2a;
    // --mdc-theme-on-primary: #fff;
}

.fluid {
    .mdc-text-field, &.mdc-select {
        width: 100%;
    }
}