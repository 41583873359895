/* Text
================================ */
.text-space,
.select-space {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    flex: 1;

    .react-select__control {
        min-height: 35px !important;
    }
    .react-select__control--is-focused {
        border-color: $primary !important;
        box-shadow: 0 0 0 1px $primary !important;
    }
    .react-select__option--is-focused {
        background-color: $primary !important;
        color: white !important;
    }
    &.small .react-select__control--is-focused {
        box-shadow: none !important;
        border: 1px solid #6641a6 !important;
    }
    &.label {
        margin-bottom: 10px;
    }
    &.flat {
        margin-bottom: 0;
    }
    &.maxWidth {
        width: 100%;
    }
    &.material,
    // &.search {
    //     .kinput {
    //         border: none;
    //         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    //         border-radius: 0;
    //         background-color: transparent !important;
    //         border-width: 1px !important;
    //     }
    // }
    // &.search {
    //     .kinput {
    //         border-bottom-color: rgba(0, 0, 0, 0) !important;
    //     }
    // }
    &.rounded {
        .kinput {
            border-radius: 20px;
        }
    }
    &.transparent {
        background-color: transparent;
    }
}

.text-space {
    max-width: 100%;
}

.label-input,
.checkbox-group-title {
    // font-style: inherit;
    // line-height: 1.33333;
    // font-weight: 500;
    // display: inline-block;
    // margin-bottom: 4px;
    // margin-top: 0px;
    // margin-bottom: 8px;
    // margin-top: 0px;
    // font-size: 14px;
    // color: #1f2b36;
    // font-weight: 500;
    // font-size: 14px;
    // // color: #4d4f51;
    font-size: 0.875rem;
    color: #202020;
    font-weight: 600;
    line-height: 1.7;
    margin-bottom: 4px;
}
.kinput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;
    background-color: rgba(9, 45, 66, 0.08);
    color: #4f545c;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.42857;
    overflow-wrap: break-word;
    overflow: hidden;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    // border-color: "transparent";
    outline: none;
    max-width: 100%;
    min-height: 35px;
    height: 35px;
    position: relative;
    // transition: background-color 0.15s ease, border 0.15s ease;

    &:hover {
        border-color: #b9bbbe;
    }
    &:focus-within {
        border-color: rgb(46, 204, 113) !important;
    }
    input {
        padding: 0 10px;
        height: 35px;
        box-sizing: border-box;
        color: inherit;
        cursor: inherit;
        font-family: inherit;
        font-size: 14px;
        width: 100%;
        background: white;
        border-width: 0px;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        outline: none;
        &::placeholder {
            color: #b9bbbe;
        }
    }
    &.textarea {
        height: auto;
        overflow-y: auto;
        padding: 7px 10px;
        &::placeholder {
            opacity: 0.7;
        }
    }
    .prefix {
        background-color: #eee;
        border-right: 1px solid #ccc;
        padding: 10px 12px;
        border-radius: 2px 0 0 2px;
        left: 0px;
        margin-left: -11px;
        margin-right: 9px;
    }
}

.text-space.white {
    .kinput {
        background-color: #fff;
        border-color: rgba(79, 84, 92, 0.3);
    }
}
.text-space.grayL .kinput,
.select-space.grayL .react-select__control {
    background-color: rgba(255, 255, 255, 0.7);
    border-color: rgba(0, 0, 0, 0.15);
    color: #0a0a0a;
    border-width: 1px;
    box-shadow: none;
    &:hover {
        border-color: #b9bbbe;
        // background-color: rgba(9,45,66,.095);
    }
    &:focus-within {
        // border-color: $primary !important;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: none !important;
    }
}
.text-space.gray .kinput,
.select-space.gray .react-select__control {
    background-color: rgba(9, 45, 66, 0.08);
    border-color: transparent;
    color: #0a0a0a;
    &:hover {
        border-color: #b9bbbe;
        // background-color: rgba(9,45,66,.095);
    }
    &:focus-within {
        // border-color: $primary;
        border-color: #a5acb0;
        outline: none;
        box-shadow: 0 0 6px #cdd2d4 !important;
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.react-select__multi-value {
    border-radius: 25px !important;
    padding: 0 5px;
    // background-color: rgba(0,0,0,0.08) !important;
    background-color: $primary-button !important;
    color: #fff !important;
}
.react-select__multi-value__label {
    color: #fff !important;

    // color: rgba(0,0,0,0.7) !important;
    font-size: 14px !important;
}

.react-select__multi-value__remove::hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.disabled .kinput {
    background-color: #eee !important;
}

.text-space.small .kinput {
    height: 34px;
    min-height: 34px;
    & input {
        font-size: 14px;
    }
}

// .text-space-inner {
//     display: flex;
//     align-items: center
// }

.input-left-icon {
    color: #798d99;
    margin-right: 10px;
    font-size: 16px;
}

.text-error {
    background: #faead0;
    // color: #fbedeb;
    font-size: 14px;
    border-color: #faead0;
    border-radius: 4px;
    padding: 0.5em 1em;
    margin-top: 5px;
}

.text-space.error {
    .kinput {
        // background: #FBEDEB;
        border-color: #f1c40f;
    }
}

.input-space {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    // & > i {
    //     margin-top: 36px;
    // }
}

.optional-label {
    color: rgba(0, 0, 0, 0.3);
    margin-left: 5px;
    font-size: 11px;
}

/* Button
================================ */
button {
    background-color: transparent;
    // outline: none;
    border: 0;
    cursor: pointer;
    &:hover,
    &:active {
        outline: none;
    }
}

// button[disabled],
// html input[disabled] {
//     cursor: default !important;
//     // background-color: #F2F8FD;
//     // color: #8992A9;
//     background-color: #f8f8f8;
//     color: rgba(33, 33, 33, 0.6);
//     // pointer-events:none;
//     // cursor: not-allowed !important;
//     // opacity: 0.5;
// }

// button[disabled] {
//     background-color: rgba(0, 0, 0, 0.1) !important;
//     box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1) !important;
// }

// input[type="date"] {
//     text-align: right;
// }

input[type="date"]:before {
    color: #b9bbbe;
    content: attr(placeholder) !important;
    margin-right: 5.5em;
}
input[type="date"]:focus:before {
    content: "" !important;
    margin-right: 0;
}

.hasValue input[type="date"]:before {
    content: "" !important;
    margin-right: 0;
}

.add-fields-space {
    .input-left-icon {
        margin-top: 38px;
    }
}

:selection {
    background: #d03e27;
}
::-moz-selection {
    background: #d03e27;
}

.form-mid {
    max-width: 800px;
}

/* Full form
===========================*/
.full-form {
    .input-space {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        // margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding-left: 15px;
    }
    .text-space {
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        max-width: 500px;
        width: 100%;
    }
    .label-input {
        margin-bottom: 0;
        margin-right: 20px;
        width: 120px;
        font-weight: 500;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
            color: #7f8fa4;
            font-size: 13px;
            font-weight: 400;
            margin-top: 3px;
        }
    }
    .kinput {
        min-height: 46px;
        height: 46px;
        font-size: 16px;
        background-color: rgba(9, 45, 66, 0.08);
    }
    input {
        background: #f8fafc;
        height: 46px;
        padding: 0 22px;
        font-size: 15px;
    }
    .horizontal-center {
        margin-bottom: 10px;
        padding-bottom: 30px;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        // padding-left: 15px;
    }
    .user-avatar {
        margin: 0;
    }
    .input-description {
        margin-top: -10px;
        color: #7f8fa4;
        margin-bottom: 20px;
    }
}

.input-description {
    // margin-top: -10px;
    color: #9b9b9b;
    margin-bottom: 15px;
}

.search-clear-btn {
    margin: 0 10px 1px 0;
    i {
        font-size: 15px;
        color: rgb(155, 156, 158);
    }
}

.react-select__dropdown-indicator {
    padding: 6px 8px !important;
}

.form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyable-btn {
    position: absolute;
    right: 0;
    margin: 0 6px;
    display: none;
    button,
    a {
        padding: 4px 16px 3px;
        font-size: 13px;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25) !important;
    }
}
.kinput:hover .copyable-btn {
    display: block;
}

.input-label {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
        BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: block;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
    overflow-wrap: break-word;
    &.disabled {
        color: rgb(161, 159, 157);
    }
}

/* Animations
===========================*/
@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

.test-toast {
    background-image: red;
}

.suffix {
    font-size: 14px;
    background: rgba(0,0,0,0.1);
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
}