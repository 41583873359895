$primary: rgb(54, 127, 238);
$primary-hover: rgb(35, 86, 179);
$primary-dark: #2275d7;

$primary-button: $primary;
$primary-button-shadow: #1f8fc5;
$primary-button-hover: $primary-hover;

$primary-lighter: #dbd0ef;
$primary-light-grey: #eff1f3;
// $primary-light-grey: #f7f9ff;
$primary-grey: #4c4c4c;
$primary-grey2: #5e5e5e;
$secondary: #0069ff;
$selection: #6641a6;

$background: #fff;
$background-light: #fafafb;

/* Text */
$text-color: #2a2a2a;

/* Logo */
// $logo-color: #6f7dd8;
// $logo-color: #54a0ff;

/* Sidenav */
// $sidenav: #373a4e;
// $sidenav: #273c75;
$sidenav: #303952;
// $sidenav-item-active: rgba(235,240,249,.2);
$sidenav-item-active: rgba(9, 30, 66, 0.42);
$sidenav-icon: rgb(222, 235, 255);
$sidenav-width: 48px;
$logo-color: #00d6d0;
$pending-color: #feca57;
$link_color: $secondary;
$border-color: #f5f5f7;

/* Layout Header */
$header-height: 55px;
$context-header-height: 40px;

$balloon: rgb(177, 229, 253);

// Sidebar
$sidebar_color: rgb(247, 248, 250);
// $sidebar_color: #f5f5f5;
$font_family: "Roboto", "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
// $font_family: apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial,
// sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
