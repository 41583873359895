.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label, 
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
    color: #abd733 !important;
}

label, div, .mdc-text-field {
    --mdc-theme-primary: #abd733;
}

.mdc-floating-label--float-above.shrink {
    // background-color: white;
    z-index: 99;
    padding-right: 9px;
    margin-left: -5px;
    padding-left: 5px;
}

.mdc-text-field--outlined .mdc-floating-label--float-above.shrink {
    background-color: white;
}