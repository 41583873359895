.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    fill: #fff;
    font-size: 18px;
    /* height: 59px; */
    /* margin-left: 15px; */
    display: flex;
    justify-content: center;
    /* padding-left: 16px; */
}

.logo svg {
    height: 26px;
    width: 26px;
    opacity: 1;
}

.logo img {
    height: 120px;
    border-radius: 8px;
}

:global(.terms-section) {
    text-align: left;
    font-family: Whitney, sans-serif;
}

:global(.terms-section p) {
    font-weight: 300;
    font-size: 18px;
    margin: 20px 0;
    letter-spacing: 0.3px;
}
:global(.terms-section h2) {
    font-size: 20px;
    font-weight: 700;
}
