:root {
    /* --siderBg: #3e474f; Team tree house header */
    /* --siderBg: #2d3339; */
    /* --siderBg: #03363D; */
    /* --siderBg: rgb(64, 67, 70); */
    --siderBg: #fff;
    --contextBg: #F7F7F7;
    --contextBgBorderColor: #e8eaed;
    --shade-00: #1d1e20;
    --shade-05: #46474f;
    --shade-10: #70727e;
    --shade-20: #91939d;
    /* --shade-30: #bfc0c7; */
    --shade-30: #dcdcdc;
    --shade-40: #dbdbdf;
    --shade-45: #efeff1;
    --shade-50: #f4f4f6;
    --shade-55: #f1f0f0;
    --shade-60: rgba(93, 105, 133, 0.1);
    /* --shade-100: #fdfdfd; */
    --shade-100: #fff;
    /* --shade-100: red; */
    --transition: all 0.13s ease-in-out;
    --radius-normal: 5px;
    --radius-large: 6px;
    --font-family-system: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    --elevation: 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 20px 0 rgba(0, 0, 0, 0.15);
    --border-normal: 2px solid var(--shade-50);
    /* --primary: #37aebb;
    --primary-hover: #32a4b0; */
    --primary: #f37021;
    --primary-hover: #f27b33;
    /* --primary: #0078d4;
    --primary-hover: #005ba1; */
}
