.react-viewer-btn[data-key="prev"],
.react-viewer-btn[data-key="next"],
.react-viewer-btn[data-key="reset"] {
    display: none;
}

.react-viewer-transition {
    transition: none !important;
}

.react-viewer-canvas,
.react-viewer {
    // z-index: 9999999;
    transition: none !important;
}

.react-viewer-image-transition {
    transition: none !important;
}

.react-viewer-mask {
    background-color: var(--shade-100) !important;
    opacity: 0.9;
}

.react-viewer-footer {
    bottom: 40px !important;
}

.react-viewer-showTotal {
    display: none;
}

.react-viewer-attribute {
    color: var(--shade-20) !important;
}

.react-viewer-btn {
    background: rgba(0,0,0,0.2) !important;;
    &:hover {
        background: rgba(0,0,0,0.6) !important;;
    }
}

.circle-loading {
    // border: 10px solid rgba(0, 0, 0, 0.1) !important;
    border-top-color: rgba(0, 0, 0, 0.2) !important;
    border-width: 8px !important;
}