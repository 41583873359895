@trelloBg: #f4f5f7;
@trelloBgInput: rgba(9, 30, 66, 0.04);

.trello-theme {
    .ant-drawer-header {
        background-color: @trelloBg;
    }
    .ant-drawer-content {
        background-color: @trelloBg;
    }
    .ant-input,
    .ant-select-selector {
        background-color: @trelloBgInput !important;
        // border-color: transparent !important;
        &:focus {
            background-color: white !important;
            // box-shadow: inset 0 0 0 2px #0079bf !important;
            // box-shadow: 0 0 0 2px #0079bf;
        }
    }
    
}
