.ant-modal-header {
    background-color: rgb(247, 248, 250);
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.ant-modal-content {
    border-radius: 4px;
}

.ant-modal-footer {
    padding: 16px;
}

.ant-modal,
.ant-table * {
    animation-duration: 0s !important;
    transition: none;
}

.ant-tag {
    cursor: unset;
}

.ant-tag-has-color {
    color: unset;
}

// .ant-form-item-explain {
//     margin-bottom: 10px;
// }

.ant-form-item-label {
    text-align: left; // USADO NA MODAL do PREVIEW
}

.full-width .ant-input-number {
    width: 100%;
}

.row-dragging {
    background: #fafafa;
    border: 2px solid var(--shade-50);
    border: 2px solid #0078d4;
    background: var(--shade-100);
    overflow: hidden;
    display: flex;
    align-items: center;
}

.row-dragging td {
    padding: 16px;
    // visibility: hidden;
    overflow: hidden;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.row-dragging .ant-table-cell-fix-right {
    display: none;
}

.ant-menu.ant-menu-dark {
    background-color: inherit !important;
}

.ant-btn > i + span {
    margin-left: 8px;
}

// .ant-dropdown-menu-item > i:first-child {
//     min-width: 12px;
//     margin-right: 8px;
//     font-size: 12px;
// }

// .ant-page-header-heading-left {
//     flex-direction: column;
//     align-items: flex-start;
// }

.copyable-button button {
    font-size: 13px;
    padding: 0 12px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.25)!important;
    display: none;
    position: absolute;
    right: 10px;
    bottom: 3px;
}

.ant-form-item-control-input:hover .copyable-button button {
    display: block;
}