// @import "awesome.all";
@import "~react-big-calendar/lib/css/react-big-calendar.css";

// Base0
@import "base/variables.scss";
@import "base/global.scss";
@import "base/text.scss";
@import "base/modules";
@import "base/forms.scss";
@import "base/variables.css";
@import "base/ant.scss";
@import "modules/quill.scss";

body {
    margin: 0;
    padding: 0;
    // font-family: "sofia", "Roboto", "Whitney", "Segoe UI", "Roboto", "Oxygen", "Roboto", "Cantarell",
    // "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-color: #f4f1ec;
    height: 100%;
    // backface-visibility: hidden;
    font-size: 14px;
    // line-height: 1.42857143;
    color: $text-color;
    text-rendering: optimizelegibility;
}

input,
textarea,
select,
button {
    font-family: $font_family;
}

button {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "Whitney";
    src: url(../assets/fonts/whitney-light.woff) format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Whitney";
    src: url(../assets/fonts/whitney-medium.woff) format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Whitney";
    src: url(../assets/fonts/whitney-bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.table-icon {
    margin-right: 15px;
    // font-size: 18px;
    color: #237dbd;
}

.blue-link {
    color: var(--primary) !important;
    text-decoration: underline !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    &:hover {
        color: var(--primary) !important;
    }
    &.gray {
        color: rgba(0, 0, 0, 0.3) !important;
        &:hover {
            color: rgba(0, 0, 0, 0.3) !important;
        }
    }
}
